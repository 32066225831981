<template>

  <div v-if="data != null">
    <div class="card mb-3">
      <div class="card-body">
        <h5 class="card-title">Server Overview</h5>
        <p class="card-text">Current Online: {{ data.current_online }}</p>
        <p class="card-text">Thread Count: {{ data.thread_count }}</p>
        <p class="card-text">CPU Count: {{ data.cpu_count }}</p>
        <p class="card-text">Uptime: {{ getTime(data.up_time) }}</p>
      </div>
    </div>

    <h2 class="mb-3">Channels</h2>
    <div class="row">
      <div class="col-md-4 mb-3" v-for="channel in data.channels" v-bind:key="channel">
        <div class="card h-100">
          <div class="card-body">
            <h5 class="card-title">Channel {{channel.name}}</h5>
            <p class="card-text">Current Online: {{ channel.current_online }}</p>
            <p class="card-text">FPS: {{ channel.fps }}</p>
            <p class="card-text">Real Online: {{channel.real_online}}</p>
            <p class="card-text">Uptime: {{ getTime(channel.up_time) }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "node-back-client/src/store";

export default {
  name: "Channel",
  methods:{
    getTime(time) {
      if(!time) return "";
      return new Date(time * 1000).toISOString().slice(11, 19);
    },
  },
  props:{
    server:Object,
  },
  data() {
    return {
      data:null
    }
  },
  async created() {
    // console.log(this.server);
    this.data = await store.custom.apis[store.custom.countryCode].servers.getChannel(this.server);
    setInterval(async () => {
      this.data = await store.custom.apis[store.custom.countryCode].servers.getChannel(this.server);
    },5000)
  }
}
</script>

<style scoped>

</style>